import Api from "../../Api";

const END_POINT_ALLQUOT = 'user/quots/all';
const END_POINT_ALLQUOTFACTURADAS = 'user/quots/all-facturadas';
const END_POINT_VIEWQUOT = 'user/quots/view';
const END_POINT_CREATEQUOT = 'user/quots/create';
const END_POINT_UPDATEQUOT = 'user/quots/update';
const END_POINT_DELETEQUOT = 'user/quots/delete';

export default {

    GetAllQuot(data) {
        return Api.get(END_POINT_ALLQUOT, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    GetAllQuotFacturadas(data) {
        return Api.get(END_POINT_ALLQUOTFACTURADAS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    GetQuot(id, auth) {
        const options = {
            headers: {'Authorization': 'Bearer ' + auth.access_token},
            params: { id: id }
        }
        return Api.get(END_POINT_VIEWQUOT,options);
    },

    CreateQuot(id_cliente, auth) {
        return Api.post(END_POINT_CREATEQUOT, {
            'client_id': id_cliente,
            /* 'estado': data.estado,
            'fecha_recepcion_ventas': data.fecha_recepcion_ventas,
            'fecha_recepcion_produccion': data.fecha_recepcion_produccion,
            'orden_de_compra': data.orden_de_compra,
            'fecha_entrega': data.fecha_entrega,
            'salida_almacen': data.salida_almacen,
            'orden_de_produccion': data.orden_de_produccion,
            'entrada_sucursal': data.entrada_sucursal,
            'subtotal': data.subtotal,
            'descuento': data.descuento,
            'iva': data.iva,
            'total': data.total */
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },

     
    UpdateQuot(data, auth) {
        return Api.patch(END_POINT_UPDATEQUOT, {
            'id': data.id,
            'estado': data.estado,
            'comentarios': data.comentarios,
            'descripcion': data.descripcion,
            'subtotal': data.subtotal,
            'descuento': data.descuento,
            'iva': data.iva,
            'total': data.total
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByQuot(id, auth) {
        return Api.delete(END_POINT_DELETEQUOT + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    },

    TrashCotizacion(id, auth){
        return Api.patch(END_POINT_UPDATEQUOT, {
            'id': id,
            'estado': 'archivado'
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    }


}