import Api from "../../Api";

const END_POINT_ALLHOJAVIAJERA = 'user/roadmap/all';
const END_POINT_CREATEHOJAVIAJERA = 'user/roadmap/create';
const END_POINT_UPDATEHOJAVIAJERA = 'user/roadmap/update';
const END_POINT_DELETEHOJAVIAJERA = 'user/roadmap/delete';

export default{
  GetAllHojaViajera(data){
    return Api.get(END_POINT_ALLHOJAVIAJERA, {
      headers: {
        'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  CreateHojaViajera(data, auth) {
    return Api.post(END_POINT_CREATEHOJAVIAJERA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateHojaViajera(data, auth) {
    return Api.patch(END_POINT_UPDATEHOJAVIAJERA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteHojaViajera(id, auth) {
    return Api.delete(END_POINT_DELETEHOJAVIAJERA + '/' + id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  }

}